import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import TextTransition, { presets } from "react-text-transition";
import TextLoop from "react-text-loop";

function Hero() {
    const qualities = [
        'decentralized',
        'Web3',
        'user-owned',
        'self-custodied'
    ];

    const [visibleQuality, setVisibleQuality] = useState(qualities[0]);

    useInterval(() => {
        setVisibleQuality(qualities[(qualities.findIndex(e => e === visibleQuality) + 1) % (qualities.length)]);
    }, 4000);
    return (
        <section className="hero-container tw-flex tw-flex-col tw-items-start lg:tw-items-center tw-justify-center tw-place-content-start
        lg:tw-place-content-center">

            <div className='tw-items-center tw-flex tw-flex-row'>
                <div className="hero">
                    Turning ideas into <br />
                    {/* <span class="great"
                    > */}
                    <TextLoop
                        // springConfig={{ stiffness: 70, damping: 10 }}
                        // adjustingSpeed={100}
                        adjustingSpeed={200}
                        interval={1500}>
                        <span className='great'>decentralized</span>
                        <span className='great'>web3</span>
                        <span className='great'>user-owned</span>
                        <span className='great'>self-custodied</span>
                    </TextLoop>
                    {/* <TextTransition
                        // delay={3000}
                        direction="down"
                        className='great'
                        text={visibleQuality}
                        springConfig={presets.gentle}
                        inline={true}
                    /> */}
                    {/* {visibleQuality} */}
                    {/* </span>  */}
                    <br />
                    {" products"}
                </div>
            </div>

            <div className="quick-offerings tw-flex-row tw-items-center tw-flex">
                <span>
                    NFTs
                </span>
                <span className="dot tw-mx-4"></span>
                <span>
                    DeFi
                </span>
                <span className="dot tw-mx-4"></span>
                <span>
                    DAO
                </span>
                <span className="dot tw-mx-4"></span>
                <span>
                    Metaverse
                </span>
            </div>

        </section >
    )
}

export default Hero



function useInterval(callback, delay) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}
